export const COMPANY = {
  name: 'RH Paie Conseils',
  shortName: 'RH Paie Conseils',
  contact: {
    address: {
      street: '30 Avenue Nelson Mandela',
      city: 'Tremblay-en-France',
      postalCode: '93290',
      country: 'France',
      full: '30 Avenue Nelson Mandela, 93290 Tremblay-en-France, France'
    },
    phone: {
      display: '06 62 49 77 56',
      link: '+33662497756'
    },
    email: 'contact@rhpaieconseils.com'
  },
  social: {
    linkedin: '#',
    twitter: '#',
    facebook: '#'
  }
};
